<template>
  <v-card
    flat
    height="100vh"
    class="mx-auto chatlist-card overflow-hidden"
    outlined
  >
    <v-toolbar flat>
      <v-tabs
        v-model="view"
        class="chat_top-tabs"
        :show-arrows="!mdAndUp"
        fixed-tabs
      >
        <v-tab
          active-class="primary light--text"
          class="text-none"
          href="#private"
          style="border-top-left-radius: 15px !important"
        >
          People
        </v-tab>
        <v-tab
          active-class="primary light--text"
          class="text-none"
          href="#group"
        >
          Group
        </v-tab>
        <v-btn
          height="100%"
          tile
          depressed
          text
          @click.stop="open_create_group_dialog = true"
          class="primary--text text-none"
        >
          New Conversation
        </v-btn>
      </v-tabs>
    </v-toolbar>
    <v-divider class="border-color--primary" />
    <template v-if="fetching">
      <v-skeleton-loader
        v-for="n in 5"
        :key="n"
        ref="skeleton"
        type="list-item-avatar-two-line"
        class="mx-auto"
      ></v-skeleton-loader>
    </template>
    <div
      v-if="!fetching"
      class="friends-online align-center justify-start d-flex flex-column"
    >
      <div class="primary--text fs-13 fw-900">
        ACTIVE CONTACTS ({{ onlineUsers.length }})
      </div>
      <v-sheet height="45" class="fullwidth">
        <v-slide-group v-if="onlineUsers.length" show-arrows>
          <v-slide-item v-for="convo in onlineUsers" :key="convo.target.id">
            <v-badge
              bordered
              bottom
              color="success"
              dot
              offset-x="10"
              offset-y="10"
            >
              <v-avatar
                :title="convo.target.fullname"
                size="45"
                class="cursor-pointer border-3 border-color--primary"
                @click="setActiveConversation(convo)"
              >
                <v-img
                  :lazy-src="settings.loader"
                  contain
                  :src="convo.target.image_url"
                >
                </v-img>
              </v-avatar>
            </v-badge>
          </v-slide-item>
        </v-slide-group>
        <div
          v-else
          class="primary--text d-flex justify-center align-center mt-3 fw-900"
        >
          No online users yet
        </div>
      </v-sheet>
    </div>
    <v-sheet
      style="height: calc(100vh - 160px)"
      id="chat-list-wrapper"
      v-if="!fetching"
    >
      <v-tabs-items v-model="view">
        <v-tab-item value="private">
          <v-list dense v-if="private_conversations.length > 0">
            <v-list-item
              :class="{
                active: is_selected_conversation(conversation)
              }"
              v-for="conversation in private_conversations"
              :key="conversation.id"
              class="border-bottom-1"
              @click="setActiveConversation(conversation)"
            >
              <v-list-item-content>
                <v-row no-gutters dense align="center">
                  <Avatar :user="conversation.target" icon-only />
                  <v-col v-if="mdAndUp">
                    <v-list-item-title class="user-message-fullname">
                      {{ conversation.target.fullname | ucwords }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="user-message-job">
                      {{ conversation.target.job_title | ucwords }}
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon
                  color="success"
                  class="has_message"
                  title="New message"
                  v-show="conversation.unread_messages_count > 0"
                >
                  mdi-email-alert-outline
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <empty v-else>
            <template v-slot:extra>
              <v-btn text class="text-none" @click="fetchConversations">
                <v-icon small left>mdi-refresh</v-icon>
                Refresh Conversations
              </v-btn>
            </template>
          </empty>
        </v-tab-item>
        <v-tab-item value="group">
          <v-list dense v-if="group_conversations.length > 0">
            <v-list-item
              class="border-bottom-1"
              :class="{ 'active primary': is_selected_conversation(group) }"
              v-for="group in group_conversations"
              :key="group.id"
              @click="setActiveConversation(group)"
            >
              <v-list-item-content>
                <v-row no-gutters dense align="center" justify="start">
                  <Avatars
                    size="40"
                    :items="group.participants"
                    :count="1"
                  ></Avatars>
                  <v-col>
                    <v-list-item-title
                      class="ml-2 primary--text"
                      v-text="group.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle class="ml-2 secondary--text">
                      Group Conversation
                    </v-list-item-subtitle>
                  </v-col>
                </v-row>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon
                  color="success"
                  class="has_message"
                  title="New message"
                  v-show="group.unread_messages_count > 0"
                >
                  mdi-email-alert-outline
                </v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
          <empty v-else>
            <template v-slot:extra>
              <v-btn text class="text-none" @click="fetchConversations">
                <v-icon small left>mdi-refresh</v-icon>
                Refresh Conversations
              </v-btn>
            </template>
          </empty>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
    <GroupChatDialog
      v-model="open_create_group_dialog"
      :user-list="canBeAddedToChat"
      :current-list="null"
      @save="handleSaveConversation"
    ></GroupChatDialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'
import GroupChatDialog from '@/modules/Chat/components/GroupChatDialog/GroupChatDialog.vue'

export default {
  name: 'ChatList',
  components: { GroupChatDialog },
  data: () => ({
    open_create_group_dialog: false,
    view: 'private'
  }),
  computed: {
    ...mapGetters(['user', 'users']),
    ...mapGetters('onlineUsers', ['all_users']),
    ...mapGetters('conversations', [
      'private_conversations',
      'group_conversations',
      'selected_conversation',
      'fetching'
    ]),
    canBeAddedToChat() {
      return _.cloneDeep(this.users).filter((i) => {
        if (this.user.id === i.id) return false
        if (this.user.is_admin || this.user.is_manager) return true
        if (this.user.client && i.is_client) return false
        if (
          !this.user.is_owner &&
          !this.user.is_admin &&
          !this.user.is_manager &&
          i.is_client
        )
          return false
        else return true
      })
    },
    onlineUsers() {
      const online_ids = this.all_users.map((i) => i.id)
      return _cloneDeep(this.private_conversations)
        .map((i) => {
          i.is_online = online_ids.includes(i.target.id)
          return i
        })
        .filter((convo) => convo.is_online)
    }
  },
  methods: {
    ...mapActions('conversations', ['fetchConversations']),
    ...mapActions('conversations', [
      'createConversation',
      'setActiveConversation'
    ]),
    is_selected_conversation(conversation) {
      return this.selected_conversation &&
        this.selected_conversation.id === conversation.id
        ? true
        : false
    },
    handleSaveConversation(payload) {
      this.createConversation({
        payload: payload,
        cb: (conversation) => {
          this.view = 'group'
          this.open_create_group_dialog = false
          this.setActiveConversation(conversation)
          this.appSnackbar('New group conversation created')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.chatlist-card {
  border-color: rgb(199 204 214) !important;
  border-radius: 15px 0 0 15px;
  .friends-online {
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid rgb(199 204 214) !important;
    .friends-online-title {
      color: $blue;
      font-weight: 500;
    }
  }
  #chat-list-wrapper {
    overflow-y: auto;
    .active {
      background-color: $blue;
      .v-list-item__title {
        color: #ffffff !important;
      }
      .v-list-item__subtitle {
        color: #ffffff !important;
      }
    }
  }
  .user-message-fullname {
    color: $blue !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .user-message-job {
    font-size: 15px !important;
    font-weight: 500 !important;
    color: $blue !important;
    padding-top: 5px;
  }
}
</style>
