<template>
  <v-card outlined class="chat-container rounded-10">
    <v-card-title class="fw-700 pl-5 fs-25 primary--text"> Chat </v-card-title>
    <v-card-text>
      <v-row dense align="stretch" justify="start">
        <div class="chatlist-group fill-height">
          <ChatList></ChatList>
        </div>
        <div class="chatbox-group fill-height">
          <ChatBox allow-message-delete></ChatBox>
        </div>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ChatList from './components/ChatBox/ChatList.vue'
import ChatBox from './components/ChatBox/ChatBox.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    ChatBox,
    ChatList
  },
  name: 'Chat',
  created() {
    if (!this.conversations.length) this.fetchConversations(true)
  },
  watch: {
    '$route.query.user': {
      handler: function (val) {
        if (val) {
          this.setActiveBaseOnUser(val)
        }
      },
      immediate: true
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.setActiveBaseOnParams(to.params.conversation_id)
    next()
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters('conversations', ['conversations'])
  },
  methods: {
    ...mapMutations('onlineUsers', [
      'add_group_conversations',
      'update_group_conversations'
    ]),
    ...mapActions('conversations', [
      'fetchConversations',
      'setActiveConversation'
    ]),
    setActiveBaseOnParams(conversation_id) {
      if (conversation_id) {
        const conversation = this.conversations.find(
          (i) => i.id === conversation_id
        )
        if (conversation) {
          this.setActiveConversation(conversation)
        }
      }
    },
    setActiveBaseOnUser(username) {
      if (username && username != this.user.username) {
        const conversation = this.conversations.find(
          (i) =>
            i.type === 'private' && i.target && i.target.username == username
        )
        if (conversation) {
          this.setActiveConversation(conversation)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.chat-container {
  .chatlist-group {
    width: 35%;
    padding: 10px 0 10px 10px;
  }
  .chatbox-group {
    width: 65%;
    padding: 10px 10px 10px 0;
  }
}
</style>
